import React from 'react';
import PropTypes from 'prop-types';
import {Link, NavLink} from 'react-router-dom';
import {withRouter} from 'react-router';
import {Popover, Menu, MenuItem} from '@blueprintjs/core';
import {MdArrowDropDown} from 'react-icons/md';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import {useSessionPermissions, useSessionUserdata, useSessionNeedsToAcceptTerms, useSessionFirmId} from 'features/session';
import {UserAvatar} from 'components/User';

import insourceLogo from 'assets/logo/insource-logo.png';

import './HeaderNav.scss';

const HeaderNav = () => {
  const {
    hasStaffRole,
    hasReviewRole,
    isStaff,
    isSearch,
    isApproach,
    isSubscriber,
    hasRecruiterRole,
    hasSubscriberViewerRole,
    hasPortalRole,
    hasDeveloperRole,
  } = useSessionPermissions();
  const needsToAcceptTerms = useSessionNeedsToAcceptTerms();
  const {trackEvent} = useMatomo();

  const handleNavbarLinkClick = title => {
    trackEvent({
      category: 'Navbar',
      action: 'NavbarLink Clicked',
      name: title,
    });
  };

  return (
    <nav className="nav">
      {(isStaff || isSubscriber || hasRecruiterRole) && (
        <div className="nav__logo">
          <NavLink exact to="/">
            <img src={insourceLogo} alt="Insource Logo" />
          </NavLink>
        </div>
      )}
      <a className="nav__toggle">
        <span>MENU</span> <i className="ss-icon ss-standard">list</i>
      </a>
      {!needsToAcceptTerms && (
        <div className="nav__list">
          {isSearch && !((hasPortalRole || hasReviewRole) && !hasDeveloperRole) && <HeaderNavItem label="Dashboard" link="/" exact />}
          {(isSearch || hasSubscriberViewerRole) && (
            <HeaderNavItem
              label="Live&nbsp;Roles"
              link="/liveroles"
              isActive={(match, location) =>
                location.pathname === '/liveroles' ||
                location.pathname.includes('/liverole/') ||
                location.pathname.includes('/role/') ||
                location.pathname.includes('/legacyrole/')
              }
              onClick={() => handleNavbarLinkClick('Liveroles')}
            />
          )}
          {hasRecruiterRole && !isSearch && (
            <HeaderNavItem
              label="Live Roles"
              link="/recruiter"
              isActive={(match, location) => location.pathname === '/recruiter' || location.pathname.includes('/liverole/')}
              onClick={() => handleNavbarLinkClick('Recruiter')}
            />
          )}
          {(isSearch || hasSubscriberViewerRole) && (
            <HeaderNavItem
              label="Pipelines"
              link="/pipelines"
              isActive={(match, location) =>
                location.pathname === '/pipelines' || location.pathname.includes('/pipeline/') || location.pathname.includes('/role/')
              }
              onClick={() => handleNavbarLinkClick('Pipelines')}
            />
          )}
          {hasStaffRole && (
            <HeaderNavItem label="Subscribers" link="/subscribers" exact onClick={() => handleNavbarLinkClick('Subscribers')} />
          )}
          {hasStaffRole && (
            <HeaderNavItem label="Organisations" link="/firms" exact onClick={() => handleNavbarLinkClick('Organisations')} />
          )}
          {isApproach && <HeaderNavItem label="Activity" link="/review" onClick={() => handleNavbarLinkClick('Activity')} />}
          {isSearch && (
            <HeaderNavItem
              label="Search"
              link="/recruit/search"
              isActive={(match, location) => location.pathname.match(/^\/(recruit)\//)}
              onClick={() => handleNavbarLinkClick('Search (recruit search)')}
            />
          )}
          {hasReviewRole && !hasStaffRole && (
            <HeaderNavItem
              label="Search"
              link="/recruit/review"
              isActive={(match, location) => location.pathname.includes('/recruit/review')}
              onClick={() => handleNavbarLinkClick('Search (recruit review)')}
            />
          )}
        </div>
      )}
      <HeaderProfile />
    </nav>
  );
};

const HeaderProfile = () => {
  const {hasStaffRole, hasReviewRole, hasSubscriberRole, hasSubscriberAdminRole, isStaff, hasPortalRole, hasRecruiterRole} =
    useSessionPermissions();
  const {fullname, avatar} = useSessionUserdata();
  const userFirmId = useSessionFirmId();
  const needsToAcceptTerms = useSessionNeedsToAcceptTerms();
  const fluidID = 41161;
  const {trackEvent} = useMatomo();

  const handleMyProfileClick = title => {
    trackEvent({
      category: 'Navbar - My Profile',
      action: 'MyProfileLink Clicked',
      name: title,
    });
  };

  return (
    <Popover
      className="nav__profile"
      targetTagName="div"
      content={
        <Menu>
          {!needsToAcceptTerms && (
            <>
              <MenuItem
                tagName="span"
                text={
                  <NavLink exact to="/preferences" className="nav__profile-link" onClick={() => handleMyProfileClick('Preferences')}>
                    Preferences
                  </NavLink>
                }
              />
              {(hasStaffRole || hasSubscriberRole || hasSubscriberAdminRole) && (
                <MenuItem
                  tagName="span"
                  text={
                    <NavLink exact to="/manage-accounts" className="nav__profile-link" onClick={() => handleMyProfileClick('Manage Team')}>
                      Manage Team
                    </NavLink>
                  }
                />
              )}
              {!hasReviewRole && (
                <MenuItem
                  tagName="span"
                  text={
                    <NavLink
                      exact
                      to="/terms-and-conditions"
                      className="nav__profile-link"
                      onClick={() => handleMyProfileClick('Terms of use')}>
                      Terms of Use
                    </NavLink>
                  }
                />
              )}
              {!isStaff && (
                <MenuItem
                  tagName="span"
                  text={
                    <a
                      href="https://insource.nz/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav__profile-link"
                      onClick={() => handleMyProfileClick('Privacy Policy')}>
                      Privacy Policy
                    </a>
                  }
                />
              )}
              {!needsToAcceptTerms && !hasPortalRole && userFirmId !== fluidID && !hasRecruiterRole && (
                <MenuItem
                  tagName="span"
                  text={
                    <NavLink
                      exact
                      to="/setup"
                      className="nav__profile-link"
                      target="_blank"
                      onClick={() => handleMyProfileClick('Overview of filters')}>
                      Overview of filters
                    </NavLink>
                  }
                />
              )}
            </>
          )}
          <MenuItem
            tagName="span"
            text={
              <Link to="/logout" className="nav__profile-link" onClick={() => handleMyProfileClick('Log out')}>
                Log Out
              </Link>
            }
          />
        </Menu>
      }>
      <div className="nav__profile-button">
        <UserAvatar fullname={fullname} avatar_url={avatar} className="nav__profile-avatar" />
        <span className="nav__profile-name">{fullname}</span>
        <MdArrowDropDown size={18} />
      </div>
    </Popover>
  );
};

const HeaderNavItem = ({label, link, exact = false, login = false, router = true, isActive}) => {
  const jsLoginClass = login ? ' js__login' : '';

  if (router) {
    return (
      <NavLink exact={exact} to={link} isActive={isActive} className={`nav__link${jsLoginClass}`} activeClassName="nav__link--active">
        {label}
      </NavLink>
    );
  }

  return (
    <a href={link} className={`nav__link${jsLoginClass}`}>
      {label}
    </a>
  );
};

HeaderNavItem.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  login: PropTypes.bool,
  router: PropTypes.bool,
  isActive: PropTypes.func,
};

HeaderNavItem.defaultProps = {
  exact: false,
  login: false,
  router: true,
  isActive: undefined,
};

export default withRouter(HeaderNav);
