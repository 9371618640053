import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {fetchCandidateWith} from '../actionCreators';

const useCandidateUpdateTabs = (candidateId, tabIndex) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!candidateId) {
      return () => null;
    }

    const doFetchCandidateUpdates = () => {
      switch (tabIndex) {
        case 'details':
          return dispatch(
            fetchCandidateWith(candidateId, 'registrations,admissions,current_address,current_address_id,subscriber_data,attachments')
          );
        case 'history':
          return dispatch(fetchCandidateWith(candidateId, 'positions,education,languages'));
        case 'expertise':
          return dispatch(fetchCandidateWith(candidateId, 'practice_areas,areas_of_expertise,workmix,sectors'));
        case 'insights':
          return dispatch(fetchCandidateWith(candidateId, 'insights'));
        case 'notes':
          return dispatch(fetchCandidateWith(candidateId, 'activity'));
        default:
          return Promise.reject();
      }
    };

    doFetchCandidateUpdates();

    return () => null;
  }, [tabIndex, candidateId, dispatch]);
};

export default useCandidateUpdateTabs;
