/* eslint-disable camelcase */
import Service, {parseParams} from 'api';

export const list = (params = {}, config) => {
  const parsedParams = parseParams({
    sort: 'name',
    limit: 0,
    ...params,
  });

  return Service.get(`authorities?${parsedParams}`, config);
};

export const getAuthorityName = authority => {
  switch (authority) {
    case 'NZLawSociety':
      return 'New Zealand';
    case 'ACTLawSociety':
      return 'Australian Capital Territory';
    case 'NSWLawSociety':
      return 'New South Wales';
    case 'NTLawSociety':
      return 'Northern Territory';
    case 'QLDLawSociety':
      return 'Queensland';
    case 'SALawSociety':
      return 'South Australia';
    case 'TASLawSociety':
      return 'Tasmania';
    case 'VICLawSociety':
      return 'Victoria';
    case 'WALawSociety':
      return 'Western Australia';
    default:
      return null;
  }
};
