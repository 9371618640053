import Service, {parseParams} from 'api';

export const getOrganisations = (params = {}, config = {}) => {
  const parsedParams = parseParams({
    sort: 'name',
    limit: 0,
    ...params,
  });

  return Service.get(`firms/search?${parsedParams}`, config);
};

export const getFirms = (params = {}, config = {}) => {
  const parsedParams = parseParams({
    sort: 'name',
    limit: 0,
    ...params,
    filter: {
      ...params.filter,
      type: 'firm',
    },
  });

  return Service.get(`firms/search?is_hidden=false&${parsedParams}`, config);
};

export const getSubscribers = (params = {}, config = {}) => {
  const parsedParams = parseParams({
    sort: 'name',
    limit: 0,
    ...params,
    filter: {
      ...params.filter,
      is_subscriber: 1,
    },
  });

  return Service.get(`firms/search?${parsedParams}`, config);
};

export const getDetail = (params = {}, config = {}) => {
  const parsedParams = parseParams({
    addfields: `is_subscriber,allowed_user_domains,allowed_recruit_for_firms,allowed_create_user_permissions,
      current_address,current_position,select_all_limit`,
    ...params,
  });

  return Service.get(`firms?${parsedParams}`, config);
};

export const removeContact = (firmId, userId) => Service.delete(`firms/remove_user/${firmId}/${userId}`);

export const post = (data = {}, config = {}) => Service.post('firms', data, config);

export const put = (id, data = {}, config = {}) => Service.put(`firms/${id}`, data, config);

export const remove = (id, config = {}) => Service.delete(`firms/${id}`, config);

export const subscribe = (id, email, config = {}) => Service.post(`firms/subscribe/${id}`, {email}, config);

export const unsubscribe = (id, config = {}) => Service.post(`firms/unsubscribe/${id}`, {}, config);
