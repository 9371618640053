import {useMemo} from 'react';

const useCandidateExpertiseDefaultValues = (candidate = {}) => {
  const {practice_areas: practiceAreas, areas_of_expertise: areasOfExpertise, workmix, sectors} = candidate || {};

  return useMemo(() => {
    if (!candidate || !Object.keys(candidate).length) {
      return {};
    }

    // This is fairly ugly... basically what it does is loop through practice areas, areas of expertise and workmix
    // to produce the required default field names for our nested structure.
    const expertiseDefaults = practiceAreas?.reduce((nextDefaults, practiceArea) => {
      const filteredAreasOfExpertise =
        areasOfExpertise?.filter(({practice_area_id: practiceAreaId}) => practiceAreaId === practiceArea.id) ?? [];

      if (filteredAreasOfExpertise.length < 1) return nextDefaults;

      // eslint-disable-next-line fp/no-mutation, no-param-reassign
      nextDefaults[`practice_area_${practiceArea.id}_areas_of_expertise`] = filteredAreasOfExpertise.map(({id}) => id);

      return filteredAreasOfExpertise.reduce((nextNestedDefaults, areaOfExpertise) => {
        const filteredWorkmix = workmix?.filter(({area_of_expertise_id: areaOfexpertiseId}) => areaOfexpertiseId === areaOfExpertise.id);

        if (filteredWorkmix.length < 1) return nextNestedDefaults;

        // eslint-disable-next-line fp/no-mutation, no-param-reassign
        nextNestedDefaults[`practice_area_${practiceArea.id}_areas_of_expertise_${areaOfExpertise.id}_workmix`] = filteredWorkmix.map(
          ({id}) => id
        );

        return nextNestedDefaults;
      }, nextDefaults);
    }, {});

    return {
      sectors: sectors?.map(sector => sector.id) ?? [],
      practice_areas: practiceAreas?.map(practiceArea => practiceArea.id) ?? [],
      ...expertiseDefaults,
    };
  }, [areasOfExpertise, candidate, practiceAreas, sectors, workmix]);
};

export default useCandidateExpertiseDefaultValues;
