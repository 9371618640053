import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getPreferences, getPreferencesLoading, getPreferencesLoaded, getPreferencesError} from '../selectors';
import {fetchPreferences} from '../actionCreators';

const useSectors = (forceReload = false) => {
  const sectors = useSelector(getPreferences);
  const loaded = useSelector(getPreferencesLoaded);
  const loading = useSelector(getPreferencesLoading);
  const error = useSelector(getPreferencesError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded || forceReload) dispatch(fetchPreferences());
    return () => null;
    // We don't want `loaded` in the dependancies for this function.
    // It can cause an infinite loop, since loaded is a timestamp.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, forceReload]);

  return [sectors, loading, error];
};

export default useSectors;
