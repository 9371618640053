import {ServiceForbidden, ServiceNotFound} from 'api';

import {getSessionUserId} from 'features/session';

import {getPreferencesLoading} from './selectors';
import * as PreferencesApi from './api';

export const fetchPreferences = () => async (dispatch, getState) => {
  if (getPreferencesLoading(getState())) return Promise.resolve();

  dispatch(setPreferencesLoading());

  try {
    const response = await PreferencesApi.list();

    dispatch(setPreferences(response.data));

    return response;
  } catch (e) {
    if (e instanceof ServiceForbidden) {
      throw e;
    }
    if (e instanceof ServiceNotFound) {
      // Handle 404 responses within this catch handler.
      dispatch(setPreferences([]));

      return null;
    }
    dispatch(setPreferencesError(e.message));
    return null;
  }
};

export const savePreference = preference => async (dispatch, getState) => {
  dispatch(setPreferencesSaving());

  const response = await PreferencesApi.post({
    user_id: getSessionUserId(getState()),
    ...preference,
  });

  dispatch(updatePreferences([preference]));

  return response.data;
};

export const setPreferences = preferences => ({
  type: 'SET_PREFERENCES',
  preferences,
});

export const updatePreferences = preferences => ({
  type: 'UPDATE_PREFERENCES',
  preferences,
});

export const setPreferencesLoading = () => ({
  type: 'SET_PREFERENCES_LOADING',
});

export const setPreferencesSaving = () => ({
  type: 'SET_PREFERENCES_SAVING',
});

export const setPreferencesError = (message = 'Sorry, something went wrong') => ({
  type: 'SET_PREFERENCES_ERROR',
  message,
});
